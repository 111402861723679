.filter-tabs {
  @apply isolate rounded-md inline-flex border border-zinc-300 divide-x divide-zinc-300;

  & button, a {
    @apply items-center px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-50 bg-white focus:z-10 shadow-inner;

    &:first-of-type {
      @apply rounded-l-md;
    }

    &:last-of-type {
      @apply rounded-r-md;
    }

    &:not(:first-of-type) {
      @apply -ml-px;
    }

    &[aria-selected="true"] {
      @apply bg-gray-100 text-gray-900;
    }
  }
}
