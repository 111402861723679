.datepicker-with-icon {
  position: relative;
}

.datepicker-with-icon::after {
  @apply fa-solid;
  pointer-events: none;
  position: absolute;
  content: "\f073";
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
  height: 1rem;
  color: #718096;
}
