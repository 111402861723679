trix-editor {
  @apply bg-white rounded-md rounded-t-none border-t-0;
}

trix-toolbar {
  @apply border-gray-500 border border-b-gray-300 rounded-t-md p-1 bg-white;
}

trix-toolbar .trix-button--icon {
  width: 1.4em;
}

.trix-button-group {
  @apply !mb-0 !border-0;
}

.trix-button {
  @apply !border-0 !m-1;

  &:not([disabled]):hover {
    @apply !bg-gray-200 !rounded;
  }
}


.trix-content {
  & h1 {
    margin: 0.5rem 0;
  }

  & h1:first-child {
    margin-top: 0;
  }

  & ul {
    @apply list-disc;
    & ul {
      list-style-type: circle;
      & ul {
        list-style-type: square;
      }
    }
  }

  & ol {
    @apply list-decimal;
  }

  & a {
    @apply text-blue-500;

    &:hover {
      @apply text-blue-700 underline;
    }
  }

  & blockquote {
    @apply border-l-4 border-blue-500;
    margin: 0.5rem 0 0.5rem 0.5em;
    padding: 0 0 0 0.6em;
  }
}

.trix-content .attachment--preview {
  width: auto;
  max-width: 100%;
}

.trix-content img {
  width: auto;
  max-height: 350px;
}

ins {
  @apply bg-green-200 no-underline text-green-900;
}

del {
  @apply bg-red-200 text-red-900;
}
