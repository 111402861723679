label.session-label {
  @apply block text-gray-700 text-sm font-bold mb-2;
}

input.session-input {
  @apply block shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight;
}

input.session-input:focus {
  @apply outline-none shadow;
}

input.session-button {
  @apply cursor-pointer bg-blue-500 text-white font-bold py-2 px-4 rounded;
}

input.session-button:hover {
  @apply bg-blue-600;
}

input.session-button:focus {
  @apply outline-none ring;
}
