.radio-tabs {
  @apply bg-gray-200 p-1 rounded-md flex space-x-1;
}
.radio-tabs input[type="radio"] {
  @apply hidden;
}
.radio-tabs label {
  @apply flex-1 text-center rounded-md cursor-pointer text-gray-700 font-medium p-3 hover:bg-gray-100;
}
.radio-tabs input[type="radio"]:checked + label {
  @apply bg-white;
}