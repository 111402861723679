.header {
  @apply flex justify-between items-center;
  @apply bg-blue-600 fixed top-0 inset-x-0 shadow p-2 z-10;
}

.header h1 {
  @apply text-lg tracking-tight font-light text-white truncate;
}

@screen xl {
  .header {
    @apply bg-transparent p-0 border-b border-gray-500 pb-2 mb-4 static shadow-none;
  }
  .header h1 {
    @apply text-2xl text-blue-700;
  }
}

@screen print {
  .header {
    @apply bg-transparent p-0 border-b border-gray-500 pb-2 mb-4 static shadow-none;
  }
  .header h1 {
    @apply text-2xl text-blue-700;
  }
}
