.default-table {
  @apply card overflow-hidden w-full !table;

  & th {
    @apply uppercase text-left px-4 py-4 text-gray-600 text-sm font-semibold;
  }

  & thead > tr {
    @apply bg-gray-100;
  }

  & tr:nth-child(even)  {
    @apply bg-gray-100;
  }

  & tbody > tr:hover {
    @apply bg-gray-200;
  }

  & td {
    @apply py-3 px-4 font-light;
  }
}

.inline-table {
  @apply w-full rounded-lg;

  & th {
    @apply uppercase text-left py-2 px-3 text-gray-800 text-sm font-semibold bg-gray-100;
  }


  & tr:nth-child(odd)  {
    @apply bg-white;
  }

  & tr:nth-child(even) {
    @apply bg-gray-100;
  }

  & tbody tr:hover {
    @apply bg-gray-200
  }

  & td {
    @apply py-2 px-3 font-light;
  }
}

.risk-profile {
  @apply text-sm;

  grid-template-columns: 8px 1fr;
  grid-template-areas:
    "child-title child-title"
    "child-green-color child-green"
    "child-yellow-color child-yellow"
    "child-red-color child-red"
    "employee-title employee-title"
    "employee-green-color employee-green"
    "employee-yellow-color employee-yellow"
    "employee-red-color employee-red";

  & > div {
    @apply border-black/5 p-3 2xl:p-4;
  }

  & div, & textarea {
    @apply m-0;
  }
}

@container (min-width: 42rem) {
  .risk-profile {
    grid-template-columns: 8px 1fr 1fr;
    grid-template-areas:
      ". child-title employee-title"
      "child-green-color child-green employee-green"
      "child-yellow-color child-yellow employee-yellow"
      "child-red-color child-red employee-red";
  }
}

.prescriptions-table {
  @apply w-full shadow-md rounded-lg mb-8 text-xs;
  /* Hack for making box-shadow work on tables in IE11 */
  border-collapse: separate;
  border-spacing: 0px;

  & tr:nth-child(1) th {
     @apply uppercase px-2 py-2 text-gray-600 font-semibold;
  }

  & > tr:not(:first-child):hover {
    & th {
      @apply bg-gray-200
    }
    & td {
      @apply bg-gray-200
    }
  }

  & tbody > tr:nth-child(odd)  {
    & > th {
      @apply bg-gray-100
    }
    & > td {
      @apply bg-gray-100
    }
  }

  & tbody > tr:nth-child(even) {
    & > th {
      @apply bg-white
    }
    & > td {
      @apply bg-white
    }
  }

  & tr td {
    @apply py-2 px-2;
  }

  /* top-left border-radius */
  & tr:first-child th:first-child {
    @apply rounded-tl-lg;
  }

  /* top-right border-radius */
  & tr:first-child th:last-child {
    @apply rounded-tr-lg;
  }

  /* bottom-left border-radius */
  & tr:last-child td:first-child {
    @apply rounded-bl-lg;
  }

  /* bottom-right border-radius */
  & tr:last-child td:last-child {
    @apply rounded-br-lg;
  }
}

.modal-table {
  @apply w-full text-sm text-left;

  & tr:nth-child(1) th {
     @apply capitalize px-2 py-2 text-gray-700 font-medium;
  }

  & > tr:not(:first-child):hover {
    & th {
      @apply bg-gray-200
    }
    & td {
      @apply bg-gray-200
    }
  }

  & tbody > tr:nth-child(even)  {
    & > th {
      @apply bg-gray-100
    }
    & > td {
      @apply bg-gray-100
    }
  }

  & tbody > tr:nth-child(odd) {
    & > th {
      @apply bg-white
    }
    & > td {
      @apply bg-white
    }
  }

  & tr td {
    @apply py-2 px-2;
  }
}

@screen 2xl {
  .prescriptions-table {
    @apply text-sm
  }
}

.fmk-table-wrapper {
  height: 40rem;
}

.fmk-table {
  @apply w-full text-sm;

  & td {
    word-break: break-all;
  }

  & .separator {
    @apply bg-gray-500 px-1 !important;
  }

  & tr:nth-child(2) th {
     @apply uppercase text-left px-4 py-4 font-normal text-gray-600 text-sm font-semibold;
  }

  & tr:not(:first-child):hover {
    & th {
      @apply bg-gray-300
    }
    & td {
      @apply bg-gray-300
    }
  }

  & tr:nth-child(odd)  {
    & th {
      @apply bg-gray-100
    }
    & td {
      @apply bg-gray-100
    }
  }

  & tr:nth-child(even) {
    & th {
      @apply bg-white
    }
    & td {
      @apply bg-white
    }
  }

  & tr.error {
    & td {
      @apply bg-red-100
    }
  }

  & tr td {
    @apply py-2 px-3 font-light;
  }
}
