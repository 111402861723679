.circle-checkbox input {
  display: none;
}

.circle-checkbox label {
  @apply w-10 h-10 rounded-full border border-gray-400 flex items-center justify-center cursor-pointer m-0;
}

.circle-checkbox input:checked + label {
  @apply bg-blue-500 text-white border-none;
}