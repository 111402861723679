.auto-complete {
  @apply relative;
  & ul {
    @apply absolute shadow-md rounded overflow-y-scroll;
    max-height: 250px;
    & li {
      @apply bg-white whitespace-nowrap px-4 py-2 text-sm cursor-pointer;

      &:hover {
        @apply bg-gray-200;
      }

      &[aria-selected="true"] {
        @apply bg-gray-200;
      }
    }
  }
}
