.spinner {
  stroke-linecap: round;
  stroke-width: 6;
  fill: none;

  & .inner-circle,
  & .outer-circle {
    stroke: #3182ce;
    stroke-dashoffset: 0;
  }

  & .inner-circle {
    stroke-dasharray: 187;
    animation: inner 1s ease-in-out infinite;
  }

  & .outer-circle {
    stroke-dasharray: 312;
    animation: outer 1s linear infinite;
    opacity: .9;
  }
}

@keyframes inner {
  0% {
    stroke-dashoffset: 187;
  }
  25% {
    stroke-dashoffset: 80;
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(360deg);
  }
}

@keyframes outer {
  0% {
    stroke-dashoffset: 312;
    transform: rotate(70deg);
  }
  60% {
    stroke-dashoffset: -312;
  }
  100% {
    stroke-dashoffset: -312;
    transform: rotate(450deg);
  }
}
