.dropdown {
  @apply ring-1 ring-black ring-opacity-5 bg-white rounded-lg shadow-xl m-0 p-0;

  & a {
    @apply block p-2 text-base;

    &:hover {
      @apply bg-gray-200;
    }
  }

  & summary {
    @apply block p-2;

    &:hover {
      @apply bg-gray-200;
    }
  }
}