.button {
  @apply cursor-pointer bg-blue-500 text-white py-2 px-4 rounded whitespace-nowrap button-transition;
}

.button:disabled {
  @apply bg-gray-500;
}

.button:disabled:hover {
  @apply bg-gray-500 cursor-not-allowed;
}

.button:hover {
  @apply bg-blue-600;
}

.button:focus {
  @apply outline-none ring;
}

.button-pill {
  @apply cursor-pointer h-8 text-white px-4 shadow rounded-full bg-green-500 inline-flex items-center button-transition;

  &:hover {
    @apply bg-green-600;
  }
}

.button-action {
  @apply cursor-pointer bg-white text-blue-700 py-1 px-4 rounded whitespace-nowrap shadow-md;
}

.button-action:hover {
  @apply bg-blue-200 text-blue-800;
}

.button-white {
  @apply bg-white text-gray-800 ring-1 ring-black ring-opacity-10;
}

.button-white:hover {
  @apply bg-gray-100;
}