.ts-wrapper {
  @apply !mt-1
}

.ts-control {
  @apply !bg-white !bg-none !rounded-md !px-3 !shadow-none !border-gray-500 flex items-center;
  min-height: 2.6rem !important;
}

.ts-control input {
  @apply !text-base
}

.ts-control .item {
  @apply text-base
}

.ts-wrapper .clear-button {
  @apply opacity-0 transition-none
}

.ts-wrapper.has-items .clear-button {
  @apply !opacity-40 text-2xl w-8 h-8 !mr-1 rounded-full text-center;
  padding-top: 6px;
}

.ts-wrapper.has-items .clear-button:hover {
  @apply !bg-gray-300 !opacity-50
}

.ts-wrapper.multi .ts-control .item {
  @apply !bg-none !bg-gray-200 text-gray-800 border-none shadow-none rounded-full px-3 py-1 text-sm;
  text-shadow: none;
}

.ts-wrapper.multi .ts-control .item .remove {
  @apply border-none text-gray-500 bg-gray-200 rounded-full ml-0 mr-1 font-bold;
  font-size: 10px;
}

.ts-wrapper.single .ts-control:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%23a0aec0' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m6 8 4 4 4-4'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  border: none;
  margin-top: -11px;
  margin-right: -6px;
}

.ts-dropdown .spinner {
  @apply animate-spin;
}

.ts-dropdown .spinner:after {
  border: 3px solid #d0d0d0;
  border-color: #d0d0d0 transparent #d0d0d0 transparent;
}

.ts-dropdown .no-results {
  @apply !p-3 !text-gray-500
}

.ts-wrapper.single.dropdown-active .ts-control::after {
  transform: rotate(180deg);
  margin-top: -11px;
}

.ts-dropdown {
  @apply card !overflow-hidden !shadow-xl !border-none !divide-y mt-1.5;
  z-index: 10000;
}

.ts-dropdown .dropdown-input-wrap {
  @apply !m-2
}

.ts-dropdown input {
  @apply !bg-gray-50 !rounded-md !border-gray-300 !border !text-base !shadow-none
}

.ts-dropdown-content {
  @apply !divide-y !text-base !max-h-[24rem]
}

.ts-dropdown-content .option {
 @apply !p-3
}

.ts-dropdown-content .option.active {
  @apply !bg-gray-100 !text-gray-900
}

.ts-control ::placeholder {
  @apply !text-gray-500
}
