html, body {
  height: 100%;
}

/* Remove number input spinners */
@layer base {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    -moz-appearance: textfield !important;
  }
}

#bit-notification-bar-spacer {
  position: absolute;
}

#sidebar {
  width: 280px;
  left: -280px;
  @apply absolute;
  top: 0px;
  bottom: 0px;
  transition: 300ms;
}

#sidebar.active {
  @apply pb-4;
  left: 0px;
}

@screen xl {
  #sidebar {
    @apply static;
  }
}

#side {
  @apply absolute;
  width: 280px;
  right: -280px;
  top: 0px;
  bottom: 0px;
  transition: 300ms;
}

#side.active {
  @apply pb-4;
  right: 0px;
}

@screen xl {
  #side {
    @apply static;
  }
}

.link {
  @apply text-blue-600 cursor-pointer;

  &:hover {
    @apply text-blue-700 underline;
  }
}

.content-header {
  @apply text-xl text-gray-800 font-light;
}

.right-sidebar {
  width: 280px;
  right: -280px;
  bottom: 40px;
  @apply fixed top-0 bg-white overflow-y-auto flex-none p-2 border-l;
  transition: all 0.3s ease-out;
}

.right-sidebar.active {
  right: 0px;
}

@screen xl {
  .right-sidebar {
    @apply block static ml-8 p-0 pl-8 bg-transparent border-l-0;
  }
}

#individual-message-container {
  @apply absolute inset-x-0;

  top: 80px;
  bottom: 50px;
}

@screen xl {
  #individual-message-container {
    @apply static;
    height: calc(100vh - 160px);
  }
}

.columns-2 {
  columns: 2;
}

summary::-webkit-details-marker { display:none; }
summary::before {
  content: "";
  padding-right: 0;
  font-size: 0.6rem;
  cursor: default;
}

.icon-switch:hover .icon-no-hover,
.icon-switch .icon-hover {
  display: none;
}
.icon-switch:hover .icon-hover {
  display: inline;
}

.inline-action {
  display: none;
}

.inline-edit .inline-action {
  display: initial;
}

.section-title {
  @apply text-sm uppercase font-semibold ml-1 text-gray-600;
}

.card-title {
  @apply font-medium text-gray-800;
}

.card {
  @apply ring-1 ring-black ring-opacity-5 bg-white rounded-lg shadow flex flex-col;
}

dialog {
  @apply ring-1 ring-black ring-opacity-5 bg-white rounded-lg shadow-xl;
}

a .label-text:hover {
  @apply bg-gray-200 -m-2 p-2 rounded-md;
}