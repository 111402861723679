details.drawer {
  & summary {
    @apply cursor-pointer block align-middle select-none text-sm text-teal-700;
    &:hover {
      @apply text-teal-800;
    }
    &:focus {
      @apply outline-none;
    }
  }

  & summary::before {
    @apply fa-solid;
    content: "\f0da";
    color: #2c7a7b;
    font-size: 15px;
    width: 15px;
  }

  &[open] {

    & summary::before {
      content: "\f0dd";
    }
  }
}
