.fc-view-container {
  @apply p-4 bg-white shadow-md rounded-lg relative;
}

@media print{
  @page {
    size: A4 landscape;
  }
}

@screen print {
  .fc-view-container {
    @apply p-0 shadow-none;
  }
}

.fc-toolbar {
  @apply flex-col
}

@screen sm {
  .fc-toolbar {
    @apply flex-row
  }
}


.fc-center {
  & h2 {
    @apply capitalize mr-4 font-extralight;
  }
}

.fc-other-month .fc-day-number {
  @apply text-gray-400;
}

.fc-day-number {
  @apply p-1 text-gray-600;
}

.fc-button-group {
  @apply shadow-none border-gray-400 border rounded;
}

.fc-button-primary {
  @apply bg-white border-transparent shadow text-gray-600;

  &:hover {
    @apply bg-blue-600 border-transparent;
  }
}

.fc-title {
  @apply whitespace-normal;
}

.fc-button-primary:disabled {
  @apply bg-gray-500 border-transparent;
}

.fc-button-primary:not(:disabled):active, .fc-button-primary:not(:disabled).fc-button-active {
  @apply bg-blue-600 border-transparent;
}

button .fc-icon-expand:before {
  @apply fa-regular;
  content: "\f31d";
  color: #666;
}

button:hover .fc-icon-expand:before {
  color: #fff
}

button.expanded .fc-icon-expand:before {
  content: "\e0a5";
}

.fc th:first-child, .fc td:first-child {
  border-left: 0px;
}

.fc th:last-child, .fc td:last-child {
  border-right: 0px;
}

td.fc-head-container.fc-widget-header {
  border-top: 0px;
}

.fc-widget-content {
  border-bottom: 0px;
}
