@import "tailwindcss/base";
@import "tailwindcss/components";

/* purgecss start ignore */
@import '@fullcalendar/core/main.css';
@import '@fullcalendar/timegrid/main.css';
@import '@fullcalendar/daygrid/main.css';
@import "fullcalendar-customization";
@import "flatpickr/dist/flatpickr";
@import "trix/dist/trix";
@import "trix-customization";
@import "dropzone/dist/dropzone";

@import "tom-select/dist/css/tom-select.default.css";
@import "tom-select-customization.css";
/* purgecss end ignore */

@import "fontawesome/css/all";
@import "fontawesome/css/v4-shims";

/* This is our css */
@import "transitions";
@import "base";
@import "components/auto-complete";
@import "components/button";
@import "components/drawer";
@import "components/dropdown";
@import "components/form";
@import "components/header";
@import "components/horizontal-scroll";
@import "components/read_more";
@import "components/session";
@import "components/spinner";
@import "components/table";
@import "components/fmk";
@import "components/recurring-picker.css";
@import "components/radio-tabs";
@import "components/filter-tabs";

@import "tailwindcss/utilities";
