.read-more-content {
  @apply overflow-hidden relative;
  max-height: 100px;
  transition: max-height 300ms ease-in;
}

.read-more-content:not(.read-more-expanded):after {
  content: "";
  position: absolute; top: 0; bottom: 0; left: -15px; right: -15px;
  box-shadow: inset white 0 -15px 20px;
}

@screen print {
  .read-more-content {
    max-height: 9999px !important;
  }

  .read-more-content:not(.read-more-expanded):after {
    content: none;
  }
}
